import React, { Component, useEffect } from "react";

/** These SiteApp need to be converted to modules: */
import "../form/playVideoForm/EmbedVideoModal.css";
import "../form/createAccountForm/BasicInfoStep.css";
import "../form/createAccountForm/CreateAccountModal.css";
import "../form/forgotPasswordForm/ForgotPasswordModal.css";

/** These WebApp need to be converted to modules: */
import "../form/formModal.css";
import "../form/form.css";
import "../pixelmixer-webapp/src/component/loader/Loader.css";
import "../pixelmixer-webapp/src/component/loader/LoaderSmall.css";
import "../pixelmixer-webapp/src/component/pinInput/PinInput.css";
import "../pixelmixer-webapp/src/component/loader/Checkmark.css";

import Layout from "../component/layout/Layout";

const NextApp = ({ Component, pageProps }) => {
  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
};

export default NextApp;
