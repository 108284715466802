import Link from "next/link";
import React from "react";

const Footer = ({ includeSignup }) => {
  return (
    <>
      {/* // <!--===== FOOTER AREA STARTS =======--> */}
      <div className="footer-section-area background-gradient">
        <div className="background-gradient-overlay"></div>
        <div className="container">
          <div className="row">
            {includeSignup && (
              <div className="col-lg-6 m-auto">
                <div className="footer-header heading2 text-center">
                  <h2 data-aos="fade-left" data-aos-duration="800">
                    Knowledge multiplies when we share it with others.
                  </h2>
                  <span data-aos="fade-right" data-aos-duration="1000">
                    No Credit Card Required
                    <img
                      src="/static/website-assets/images/icons/star-icon1.svg"
                      alt=""
                    />
                  </span>
                  <span data-aos="fade-right" data-aos-duration="1000">
                    Cancel at Any Time
                  </span>
                  <div
                    className="div text-center"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <Link href="/createAccount" className="header-btn">
                      Get Started Now<i class="fa-solid fa-power-off"></i>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-12">
              <div className="footer-last-section">
                <div className="row">
                  <div className="col-lg col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <img
                        src="/static/website-assets/images/custom/logo/play-logo-white.svg"
                        alt=""
                      />
                      <p>
                        We believe in transparency, equality and freedom of
                        expression.
                      </p>
                      <ul className="social-links">
                        <li>
                          <a href="https://www.linkedin.com/company/38111089">
                            <i className="fa-brands fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/profile.php?id=100028486284957">
                            <i className="fa-brands fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/channel/UCwoiVrPCyyA2af_JBITt2CA">
                            <i className="fa-brands fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/pixelmixerapp">
                            <i className="fa-brands fa-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Apps</h3>
                      <ul>
                        <li>
                          <a
                            href="https://apps.apple.com/us/app/pixelmixer/id1354592267"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PixelMixer for iOS
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://chrome.google.com/webstore/detail/pixelmixer-for-chrome/hjofmchdijcfiokikfkknhkfkmbldmeg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PixelMixer for Chrome
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://slack.com/apps/A02AC4SRDM4-pixelmixer-for-slack"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PixelMixer for Slack
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://marketplace.zoom.us/apps/y8QYxcmwR_aZcg9ec853sw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PixelMixer for Zoom
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://apphub.webex.com/applications/meeting-intelligence-pixelmixer"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PixelMixer for Webex
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Discover</h3>
                      <ul>
                        <li>
                          <a href="https://blog.pixelmixer.com">Our Blog</a>
                        </li>
                        <li>
                          <a href="/pricing">Plans & Pricing</a>
                        </li>
                        <li>
                          <a
                            href="https://help.pixelmixer.com/en/collections/2539870-announcements"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Announcements
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://help.pixelmixer.com/en/articles/5636623-pixelmixer-for-slack"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Slack Integration
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Use Cases</h3>
                      <ul>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            Software Development
                          </a>
                        </li>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            Program Management
                          </a>
                        </li>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            Product Engineering
                          </a>
                        </li>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            High-tech Manufacturing
                          </a>
                        </li>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            UX/Creative Design
                          </a>
                        </li>
                        <li>
                          <a href="https://blog.pixelmixer.com/why-do-teams-love-pixelmixer/">
                            Engineering
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg col-md-6 col-12">
                    <div className="footer-auhtor-area">
                      <h3>Support</h3>
                      <ul>
                        <li>
                          <a href="https://help.pixelmixer.com/en/articles/4415965-start-here-admin">
                            Getting Started
                          </a>
                        </li>
                        <li>
                          <a href="https://help.pixelmixer.com">
                            Knowledge Base
                          </a>
                        </li>
                        <li>
                          <a href="#">Live Chat</a>
                        </li>
                        <li>
                          <a href="https://help.pixelmixer.com/en/articles/5816339-contact-support">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="copyright-social-area">
                      <ul>
                        <li className="copy">
                          <p>&copy;2024 PixelMixer, Inc.</p>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a href="https://help.pixelmixer.com/en/articles/4416006-pixelmixer-privacy-policy">
                            Your Privacy
                          </a>
                        </li>
                        <li className="terms">
                          <a href="https://help.pixelmixer.com/en/articles/4416008-terms-of-service">
                            Terms of Service
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--===== FOOTER ENDS =======--> */}
    </>
  );
};

export default Footer;
