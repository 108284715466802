import React from "react";
import Footer from "../footer/Footer";

const Layout = ({ children }) => {
  return (
    <div id="page" className="page">
      <main>{children}</main>
    </div>
  );
};

export default Layout;
